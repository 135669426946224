/*----------------------------------------------------
@File: Default Styles
@Author: Rocky
@URL: http://wethemez.com
Author E-mail: rockybd1995@gmail.com

This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.
---------------------------------------------------- */
/*=====================================================================
@Template Name: Cerative Agency
@Author: Rocky
@Developed By: Rocky
@Developer URL: http://rocky.wethemez.com
Author E-mail: rockybd1995@gmail.com

@Default Styles

Table of Content:
01/ Variables
02/ predefin
03/ header
04/ banner
05/ slider
06/ feature
07/ button
08/ service
09/ testimonials 
10/ blog 
11/ contact 
12/ footer 
=====================================================================*/
/*----------------------------------------------------*/
/*font Variables*/
/*Color Variables*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,900");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
.col-md-offset-right-1,
.col-lg-offset-right-1 {
    margin-right: 8.33333333%;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Montserrat" !important;
}
ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

a {
    text-decoration: none;
}

a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}
.row {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 2rem;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 !important;
}
.row.m0 {
    margin: 0px;
}

.p0 {
    padding: 0px;
}
*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
body {
    line-height: 28px;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    padding: 0px;
}

#success {
    display: none;
}

#error {
    display: none;
}

.display_table {
    display: table;
    width: 100%;
}

.display_table_row {
    display: table-row;
}

.display_table_cell {
    display: table-cell;
    float: none !important;
}

.p_100 {
    padding: 100px 0px;
}

iframe {
    border: none;
}

i:before {
    margin-left: 0px !important;
}

/* Main title css
============================================================================================ */
.cTitle {
    text-align: center;
    padding-bottom: 40px;
}

.cTitle img {
    padding-bottom: 30px;
}

.cTitle h6 {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #7c8d93;
    padding-bottom: 10px;
}

.cTitle h2 {
    font-size: 48px;
    color: #0b1033;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.lTitle {
    padding-bottom: 55px;
}

.lTitle img {
    padding-bottom: 30px;
}

.lTitle h6 {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #7c8d93;
    padding-bottom: 10px;
}

.lTitle h2 {
    font-size: 48px;
    color: #0b1033;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    line-height: 1;
}

.lTitle h2 span {
    font-style: italic;
    color: #6fbbfe;
}

/* Main title css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Main Menu Area css
============================================================================================ */
.main_menu_area {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 30;
    padding: 0px 45px;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.main_menu_area .navbar {
    padding: 0px;
    border-radius: 0px;
    background-color: transparent !important;
}

.main_menu_area .navbar .navbar-toggler {
    border: none;
    border-radius: 0px;
    padding: 0px;
}

.main_menu_area .navbar .navbar-toggler span {
    height: 3px;
    width: 25px;
    display: block;
    background: #0b1033;
    margin-bottom: 3px;
}

.main_menu_area .navbar .navbar-toggler span:last-child {
    margin-bottom: 0px;
}

.navbar_collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
}

@media (min-width: 992px) {
    .main_menu_area .navbar .navbar_collapse {
        text-align: right;
        display: block !important;
    }
}

.navbar_nav {
    /* display: inline-block;
  text-align: right; */
    display: flex;
    align-content: center;
    justify-content: flex-end;
}

.navbar_brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.main_menu_area .navbar .navbar_nav .nav_item {
    display: inline-block;
    margin-right: 55px;
}

.main_menu_area .navbar .navbar_nav .nav_item .nav_link {
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #333;
    letter-spacing: 0.65px;
    padding: 0px;
    line-height: 118px;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}

.main_menu_area .navbar .navbar_nav .nav_item:last-child {
    margin-right: 0px;
}

@media (min-width: 992px) {
    .main_menu_area .navbar .navbar_nav .nav_item.submenu .dropdown-menu {
        -webkit-transform: scaleY(0);
        -ms-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transition: all 400ms ease-out;
        -o-transition: all 400ms ease-out;
        transition: all 400ms ease-out;
        display: block;
        -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
        transform-origin: 50% 0;
        min-width: 250px;
        background: #171717;
        left: auto;
        right: 0px;
        top: 90%;
        border-radius: 0px;
        border: none;
        padding: 30px 0px 30px 0px;
        -webkit-box-shadow: none;
        box-shadow: none;
        visibility: hidden;
    }
}

.main_menu_area .navbar .navbar_nav .nav_item.submenu .dropdown-menu .nav_item {
    margin-right: 0px;
    display: block;
}

.main_menu_area .navbar .navbar_nav .nav_item.submenu .dropdown-menu .nav_item .nav_link {
    display: block;
    line-height: 38px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.48px;
    font-size: 16px;
    padding: 0px 30px;
    text-transform: inherit;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
    font-weight: normal;
    text-shadow: none;
}

.main_menu_area .navbar .navbar_nav .nav_item.submenu .dropdown-menu li:hover .nav_link {
    color: #74b5fe;
}

@media (min-width: 992px) {
    .main_menu_area .navbar .navbar_nav li:hover.submenu .dropdown-menu {
        -webkit-transform: scaleY(1);
        -ms-transform: scaleY(1);
        transform: scaleY(1);
        visibility: visible;
    }
}

.main_menu_area .navbar .navbar_nav .nav_item:hover .nav_link,
.main_menu_area .navbar .navbar_nav .nav_item.active .nav_link {
    text-shadow: 0px 0px 4px rgba(8, 24, 40, 0.4);
}

.main_menu_area.navbar_fixed {
    position: fixed;
    width: 100%;
    top: -70px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    -webkit-transform: translateY(70px);
    -ms-transform: translateY(70px);
    transform: translateY(70px);
    -webkit-transition: background 500ms ease, -webkit-transform 500ms ease;
    transition: background 500ms ease, -webkit-transform 500ms ease;
    -o-transition: transform 500ms ease, background 500ms ease;
    transition: transform 500ms ease, background 500ms ease;
    transition: transform 500ms ease, background 500ms ease, -webkit-transform 500ms ease;
    -webkit-transition: transform 500ms ease, background 500ms ease;
    z-index: 999999;
}

.main_menu_area.navbar_fixed .navbar .navbar_nav .nav_item .nav_link {
    line-height: 70px;
}

/* End Main Menu Area css
============================================================================================ */
/*---------------------------------------------------- */

.heroWrap {
    background-image: url("../../../Assets/Images/slider-bg-1.png");
    width: 100%;
    height: 700px;
    position: inherit;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.heroWrap.heroWrap2 {
    height: 700px !important;
    position: relative;
}
.heroWrap.heroWrap2 .overlay {
    width: 100%;
    opacity: 0.05;
    height: 700px;
}
.heroWrap.heroWrap2 .sliderText {
    height: 700px !important;
}

.ftcoDegreeBg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../../Assets/Images/Orbiz_BG_2.png);
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
}
.ftcoDegreeBg span {
    font-size: 5rem;
    /* width: 40%; */
    font-weight: 900;
    line-height: 6rem;
    text-align: center;
    padding-bottom: 1rem;
    transform: translateY(-10%);
}
.ftcoDegreeBg span.textAnimation {
    width: 100%;
    text-align: center;
    display: block;
    background: linear-gradient(102.25deg, #70bafe 9.7%, #7263ff 46.33%, #70bafe 87.89%);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: header 2s linear infinite;
    animation: header 3s linear infinite;
}

@keyframes header {
    0% {
        background-position: 0% 50%;
        background: linear-gradient(102.25deg, #70bafe 87.89%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    50% {
        background-position: 100% 50%;
        background: linear-gradient(102.25deg, #7263ff 46.33%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    100% {
        background-position: 0% 50%;
        background: linear-gradient(102.25deg, #70bafe 9.7%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

/* .ftcoDegreeBg:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -30px;
    overflow: visible;
    width: 50%;
    height: 70px;
    z-index: 1;
    -webkit-transform: skewY(-4deg);
    -moz-transform: skewY(-4deg);
    -ms-transform: skewY(-4deg);
    -o-transform: skewY(-4deg);
    transform: skewY(-4deg);
    background-color: #fff;
}
.ftcoDegreeBg:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -30px;
    overflow: visible;
    width: 50%;
    height: 70px;
    z-index: 1;
    -webkit-transform: skewY(4deg);
    -moz-transform: skewY(4deg);
    -ms-transform: skewY(4deg);
    -o-transform: skewY(4deg);
    transform: skewY(4deg);
    background-color: #fff;
} */
/*----------------------------------------------------*/
/* Banner Area css
============================================================================================ */
.bannerArea {
    position: relative;
    z-index: 3;
    overflow: hidden;
}

.bannerArea:before {
    content: "";
    background: url(../../../Assets/Images/banner-bg.png) no-repeat scroll center right;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    max-width: 1233px;
    width: 100%;
}

.bannerInnerText {
    padding-top: 200px;
    position: relative;
    padding-bottom: 95px;
}

.bannerInnerText:before {
    content: "";
    width: 213px;
    height: 1px;
    position: absolute;
    background: #ced0e0;
    left: 0px;
    bottom: 0px;
}

.bannerInnerText h2 {
    font-size: 72px;
    color: #0b1033;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
}

.bannerInnerText p {
    font-size: 24px;
    color: #6b7d8e;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    padding-top: 15px;
}
.mainIndex {
    background: url("../../../Assets/Images/slider-bg-1.png") no-repeat scroll center center;
    background-size: cover;
}
/* End Banner Area css
============================================================================================ */
/* Mosue*/
.mouse {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    z-index: 2;
}

.mouseIcon {
    width: 80px;
    height: 80px;
    border: 1px solid #e86ed0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    background: #e86ed0;
    cursor: pointer;
    position: relative;
    text-align: center;
    margin: 0 auto;
    display: block;
}

.mouseWheel {
    height: 80px;
    display: block;
    background: transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-animation: 1.6s ease infinite wheel-up-down;
    -moz-animation: 1.6s ease infinite wheel-up-down;
    animation: 1.6s ease infinite wheel-up-down;
    color: #fff;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*---------------------
  Chooseus Section
-----------------------*/
.chooseusSection {
    padding-bottom: 55px;
}

.chooseusText {
    margin-bottom: 40px;
}
.chooseusText .sectionTitle {
    margin-bottom: 24px;
}
.chooseusText .sectionTitle h4 {
    color: #ffffff;
}
.chooseusText p {
    color: #ffffff;
    opacity: 0.7;
}

.chooseusFeatures {
    overflow: hidden;
}
.chooseusFeatures .cfItem {
    width: calc(50% - 40px);
    float: left;
    margin-right: 40px;
    margin-bottom: 35px;
    overflow: hidden;
}
.chooseusFeatures .cfItem .cfPic {
    width: 80px;
    height: 80px;
    background: #ffffff;
    border-radius: 50%;
    line-height: 80px;
    text-align: center;
    float: left;
    margin-right: 20px;
}
.chooseusFeatures .cfItem .cfText {
    overflow: hidden;
}
.chooseusFeatures .cfItem .cfText h5 {
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 10px;
}
.chooseusFeatures .cfItem .cfText p {
    margin-bottom: 0;
    color: #ffffff;
    opacity: 0.7;
}

.spad {
    padding-top: 100px;
    padding-bottom: 100px;
    margin: 6rem 0;
}

/* End Slider css
============================================================================================ */
/* Testimonials Slider css
============================================================================================ */
.testimonialsArea {
    padding-top: 190px;
    padding-bottom: 130px;
    border-bottom: 1px solid #bbbec1;
}

.testimonialsSlider {
    position: relative;
}

.testimonialsSlider:before {
    content: "";
    background: url(../../../Assets/Images/3d-shap.png) no-repeat scroll center center;
    height: 600px;
    max-width: 524px;
    width: 100%;
    position: absolute;
    left: 50%;
    top: -210px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1;
}

.testimonialsSlider .owl-item.center {
    color: #fff;
}

.testimonialsSlider .owl-item.center h3 {
    color: #fff;
}

.testimonialsSlider .owl-item.center p {
    color: #fff;
}

.media .mediaBody h5 {
    color: #000;
}

.testiItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.testiItem h3 {
    font-size: 36px;
    color: #081828;
    font-weight: 800;
}
.testiItem span {
    font-size: 32px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: #081828;
}

.testiItem p {
    font-size: 15px;
    color: #081828;
    line-height: 28px;
    font-weight: normal;
    text-align: left;
    text-align: center;
    /* padding: 20px 0px 55px 0px; */
}

.testiItem .media .dFlex {
    padding-right: 20px;
}

.testiItem .media .mediaBody h4 {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #0b1033;
    padding-bottom: 8px;
}

.testiItem .media .mediaBody h5 {
    font-family: "Roboto", sans-serif;
    color: #7c8d93;
    font-weight: 500;
    font-size: 12px;
}

.testimonialsSlider .owlDots {
    text-align: center;
    z-index: 4;
    margin-top: 50px;
}

.testimonialsSlider .owlDots .owlDot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #fff;
    display: inline-block;
    margin-right: 8px;
}

.testimonialsSlider .owlDots .owlDot:last-child {
    margin-right: 0px;
}

/* End Testimonials Slider css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Feature Area css
============================================================================================ */
.featureArea {
    padding-top: 70px;
}

.featureItem {
    text-align: center;
    padding: 40px 0px 60px 0px;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}

.featureItem .fIcon {
    min-height: 91px;
    position: relative;
}

.featureItem .fIcon img {
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.featureItem h4 {
    color: #0b1033;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 24px;
    padding: 45px 0px 20px 0px;
}

.featureItem p {
    font-size: 15px;
    line-height: 28px;
    font-family: "Roboto", sans-serif;
    color: #7c8d93;
    font-weight: 500;
    max-width: 290px;
    margin: auto;
    padding-bottom: 60px;
}

.featureItem .moreBtn {
    opacity: 0;
}

.featureItem:hover {
    -webkit-box-shadow: 0px 14px 38px 0px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 14px 38px 0px rgba(0, 0, 0, 0.11);
}

.featureItem:hover .moreBbtn {
    opacity: 1;
}

.serviceFeature {
    border-bottom: 1px solid #bbbec1;
    padding-top: 100px;
    overflow: hidden;
}

.serviceFeature .featureInner {
    margin-bottom: -50px;
}

.serviceFeature .featureInner .featureItem {
    margin-bottom: 50px;
}

.serviceFeature .featureInner .featureItem .moreBtn {
    opacity: 1;
    background: transparent;
    border-color: transparent;
    background-image: -webkit-gradient(linear, left top, right top, from(#6ebdfe), to(#9b8aff));
    background-image: -webkit-linear-gradient(left, #6ebdfe, #9b8aff);
    background-image: -o-linear-gradient(left, #6ebdfe, #9b8aff);
    background-image: linear-gradient(left, #6ebdfe, #9b8aff);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
    background-size: 200% auto;
}

.serviceFeature .featureInner .featureItem:hover .moreBtn {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, right top, from(#70bafe), color-stop(51%, #998dff), to(#70bafe));
    background-image: -webkit-linear-gradient(left, #70bafe 0%, #998dff 51%, #70bafe 100%);
    background-image: -o-linear-gradient(left, #70bafe 0%, #998dff 51%, #70bafe 100%);
    background-image: linear-gradient(to right, #70bafe 0%, #998dff 51%, #70bafe 100%);
    border-color: rgba(204, 241, 255, 0.5);
    background-clip: inherit;
}

.getInTouchBtn:hover {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, right top, from(#70bafe), color-stop(51%, #998dff), to(#70bafe));
    background-image: -webkit-linear-gradient(left, #70bafe 0%, #998dff 51%, #70bafe 100%);
    background-image: -o-linear-gradient(left, #70bafe 0%, #998dff 51%, #70bafe 100%);
    background-image: linear-gradient(to right, #70bafe 0%, #998dff 51%, #70bafe 100%);
    border: solid 2px rgba(255, 255, 255, 1);
    background-clip: inherit;
}

/* End Feature Area css
============================================================================================ */
/* Team People Area css
============================================================================================ */
.team_people_area {
    overflow: hidden;
    padding-top: 50px;
}

.team_people_text {
    max-width: 615px;
    float: right;
}

.team_people_text p {
    font-family: "Roboto", sans-serif;
    color: #7c8d93;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    padding-bottom: 45px;
}

.team_people_text ul {
    padding-left: 20px;
}

.team_people_text ul li {
    width: calc(100% / 2);
    float: left;
    margin-bottom: 15px;
}

.team_people_text ul li a {
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    color: #7c8d93;
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
    position: relative;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}

.team_people_text ul li a:before {
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: #a6d7ff;
    position: absolute;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}

.team_people_text ul li:nth-child(even) {
    padding-left: 22px;
}

.team_people_text ul li:last-child {
    margin-bottom: 0px;
}

.team_people_text ul li:hover a {
    color: #a6d7ff;
}

.team_people_text ul li:hover a:before {
    left: 4px;
}

.team_img_inner {
    max-width: 100%;
    margin-bottom: -20px;
    margin-left: 15px;
}

.team_img_inner .row {
    margin-left: -10px;
    margin-right: -10px;
}

.team_img_inner .row .col-lg-6 {
    padding: 0px 10px;
    margin-bottom: 20px;
    overflow: hidden;
}

.team_img_inner .team_img_item {
    overflow: hidden;
    transition: all 0.3s ease-in;
    padding: 1rem 2rem;
}
.team_img_item:hover {
    transform: scale(1.1);
}

/* End Team People Area css
============================================================================================ */
/* Challange Area css
============================================================================================ */
.challange_area {
    overflow: hidden;
}

.challange_text_inner {
    max-width: 545px;
    float: right;
}

.challange_text_inner p {
    font-size: 15px;
    line-height: 28px;
    color: #7c8d93;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    padding-right: 25px;
}

.challange_text_inner .c_video {
    padding-top: 35px;
}

.challange_text_inner .c_video a {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #0b1033;
}

.challange_text_inner .c_video a img {
    padding-right: 15px;
}

.challange_img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.challange_img .challange_img_inner {
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-shadow: 0px 27px 68px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 27px 68px 0px rgba(0, 0, 0, 0.4);
}

/* End Challange Area css
============================================================================================ */
/* We Are Company Area css
============================================================================================ */
.left_company_text p {
    font-size: 15px;
    color: #7c8d93;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    padding-right: 20px;
}

.company_skill {
    padding-top: 90px;
}

.company_skill p {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    line-height: 28px;
    color: #7c8d93;
    font-weight: 500;
    padding-bottom: 70px;
}

.our_skill_inner .single_skill {
    margin-bottom: 20px;
}

.our_skill_inner .single_skill h3 {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    color: #7c8d93;
    font-weight: 500;
    padding-bottom: 12px;
}

.our_skill_inner .single_skill .progress {
    height: 5px;
    background: #f4f8fc;
    border-radius: 0px;
    overflow: visible;
}

.our_skill_inner .single_skill .progress .progress-bar {
    background-image: -moz-linear-gradient(0deg, #6ebdfe 0%, #9b8aff 100%);
    background-image: -webkit-linear-gradient(0deg, #6ebdfe 0%, #9b8aff 100%);
    background-image: -ms-linear-gradient(0deg, #6ebdfe 0%, #9b8aff 100%);
    position: relative;
}

.our_skill_inner .single_skill .progress .progress-bar .progress_parcent {
    position: absolute;
    right: 0px;
    top: -40px;
    background-image: -moz-linear-gradient(10deg, #6ebdfe 0%, #9b8aff 100%);
    background-image: -webkit-linear-gradient(10deg, #6ebdfe 0%, #9b8aff 100%);
    background-image: -ms-linear-gradient(10deg, #6ebdfe 0%, #9b8aff 100%);
    color: #fff;
    padding: 0px 16px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    line-height: 34px;
    border-radius: 17px;
}

.our_skill_inner .single_skill:last-child {
    margin-bottom: 0px;
}

.talk_area {
    background-image: -moz-linear-gradient(10deg, #6ebdfe 0%, #9b8aff 100%);
    background-image: -webkit-linear-gradient(10deg, #6ebdfe 0%, #9b8aff 100%);
    background-image: -ms-linear-gradient(10deg, #6ebdfe 0%, #9b8aff 100%);
    padding: 160px 0px 170px 0px;
    border-bottom: 2px solid #fff;
}

.talk_text {
    text-align: center;
}

.talk_text h4 {
    font-size: 48px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    padding-bottom: 26px;
}

.talk_text a {
    font-size: 48px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
}

/* End We Are Company Area css
============================================================================================ */
/* Service Solution Area css
============================================================================================ */
.service_solution_area {
    background: url(../../../Assets/Images/service-bg.jpg) no-repeat fixed center right;
    background-size: cover;
    position: relative;
    z-index: 3;
}

.service_solution_area:before {
    content: "";
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
}

.s_solution_item p {
    font-size: 15px;
    line-height: 28px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #7c8d93;
}

.solution_collaps {
    margin-top: 60px;
}

.solution_collaps .card {
    margin-bottom: 25px;
    border: none;
}

.solution_collaps .card:last-child {
    margin-bottom: 0px;
}

.solution_collaps .card .card-header {
    padding: 0px;
    border: none;
    background-color: transparent;
}

.solution_collaps .card .card-header h5 a {
    background: #79ccff;
    background: -moz-linear-gradient(left, #79ccff 0%, #9b75ff 100%);
    background: -webkit-linear-gradient(left, #79ccff 0%, #9b75ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#79ccff', endColorstr='#9b75ff',GradientType=1 );
    display: block;
    color: #fff;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    padding: 11px 25px 11px 20px;
    line-height: 26px;
    position: relative;
}

.solution_collaps .card .card-header h5 a i {
    display: inline-block;
    font-style: normal;
    position: absolute;
    right: 20px;
    line-height: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.solution_collaps .card .card-header h5 a i + i {
    display: none;
}

.solution_collaps .card .card-header h5 .collapsed i {
    display: none;
}

.solution_collaps .card .card-header h5 .collapsed i + i {
    display: inline-block;
}

.solution_collaps .card .card-body p {
    font-size: 15px;
    line-height: 28px;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    color: #7c8d93;
}

/* End Service Solution Area css
============================================================================================ */
/* Elements Page Area css
============================================================================================ */
.elements_title {
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #7c8d93;
    font-weight: normal;
    padding-bottom: 75px;
}

.buttons_area .more_btn {
    width: 176px;
}

.buttons_area .submit_btn {
    border: none;
    position: relative;
    width: 158px;
    height: 56px;
    margin-left: 45px;
    cursor: pointer;
    line-height: 56px;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.buttons_area .submit_btn:before {
    content: "";
    width: 176px;
    height: 67px;
    border: 2px solid #bee1fe;
    position: absolute;
    left: -8.5px;
    top: -5.5px;
    border-radius: 33px;
}

.buttons_area .submit_btn:focus {
    border: none;
}

.buttons_area .solid_btn {
    margin-left: 40px;
}

.progress_bar_area .company_skill {
    padding-top: 0px;
}

.progress_bar_area .solution_collaps {
    margin-top: 30px;
}

.element_service {
    padding-top: 0px;
    padding-bottom: 100px;
    border-bottom: 0px;
}

.element_service .elements_title {
    padding-bottom: 65px;
}

.mileston_area {
    padding-bottom: 100px;
}

.mileston_area .elements_title {
    padding-bottom: 65px;
}

.mileston_area .col-lg-3 {
    text-align: center;
}

.mileston_item {
    display: inline-block;
    text-align: left;
}

.mileston_item h4 {
    font-size: 72px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background: -moz-linear-gradient(left, #6ebcfe 0%, #997aff 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #6ebcfe 0%, #997aff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#6ebcfe), to(#997aff));
    background: -o-linear-gradient(left, #6ebcfe 0%, #997aff 100%);
    background: linear-gradient(to right, #6ebcfe 0%, #997aff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ebcfe', endColorstr='#997aff',GradientType=1 );
    /* IE6-9 */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mileston_item h5 {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #0b1033;
}

/* End Elements Page Area css
============================================================================================ */
/* Best 3d Area css
============================================================================================ */
.best_3d_area {
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 887px;
}

.best_3d_area .left_3d {
    width: calc(50% - 160px);
    float: left;
    position: relative;
}

.best_3d_area .left_3d:before {
    content: "";
    background: url(../../../Assets/Images/3d-slider-shap.png) no-repeat scroll center center;
    background-size: cover;
    min-height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 797px;
    z-index: -1;
}

.best_3d_area .right_text {
    width: calc(50% + 160px);
    float: right;
}

.shap_slider_inner {
    max-width: 315px;
    float: right;
    margin-right: 160px;
    margin-top: 300px;
}

.shap_slider_inner .item {
    color: #fff;
}

.shap_slider_inner .item img {
    width: auto;
}

.shap_slider_inner .item h4 {
    font-size: 24px;
    line-height: 28px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
}

.shap_slider_inner .item p {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 28px;
    padding: 25px 0px 55px 0px;
}

.shap_slider_inner .item .media {
    vertical-align: middle;
}

.shap_slider_inner .item .media img {
    -ms-flex-item-align: center;
    align-self: center;
}

.shap_slider_inner .item .media .media-body {
    padding-left: 18px;
}

.shap_slider_inner .item .media .media-body h5 {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 28px;
    color: #0b1033;
    padding-bottom: 5px;
}

.shap_slider_inner .item .media .media-body h6 {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.shap_slider_inner .owl-dots {
    text-align: right;
    margin-top: 70px;
}

.shap_slider_inner .owl-dots .owl-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #fff;
    display: inline-block;
    margin-right: 10px;
}

.shap_slider_inner .owl-dots .owl-dot:last-child {
    margin-right: 0px;
}

.shap_slider_inner .owl-dots .owl-dot.active {
    background: #97ccfe;
}

.text_3d {
    display: inline-block;
    padding-left: 80px;
    padding-top: 150px;
    width: 54%;
    float: left;
    padding-right: 5px;
}

.text_3d p {
    font-size: 15px;
    line-height: 28px;
    color: #7c8d93;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.shap_mobile {
    padding-top: 85px;
    float: right;
    width: 46%;
    padding-left: 130px;
}

/* End Best 3d Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Button Area css
============================================================================================ */
.more_btn {
    padding: 0px 46px;
    background-image: -webkit-gradient(linear, left top, right top, from(#6fbcfe), color-stop(51%, #998dff), to(#6fbcfe));
    background-image: -webkit-linear-gradient(left, #6fbcfe 0%, #998dff 51%, #6fbcfe 100%);
    background-image: -o-linear-gradient(left, #6fbcfe 0%, #998dff 51%, #6fbcfe 100%);
    background-image: linear-gradient(to right, #6fbcfe 0%, #998dff 51%, #6fbcfe 100%);
    background-size: 100% auto;
    z-index: 2;
    display: inline-block;
    -webkit-transition: all 400ms ease-out;
    -o-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    text-align: center;
    color: #fff;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    line-height: 30px;
    border: 3px solid rgba(204, 241, 255, 0.5);
    border-radius: 33px;
    position: absolute;
    bottom: 5px;
    /* left: 2px; */
}

.more_btn:hover {
    background-position: right center;
    color: #fff;
}

.submit_btn {
    background-image: -webkit-gradient(linear, left top, right top, from(#70bafe), color-stop(51%, #998dff), to(#70bafe));
    background-image: -webkit-linear-gradient(left, #70bafe 0%, #998dff 51%, #70bafe 100%);
    background-image: -o-linear-gradient(left, #70bafe 0%, #998dff 51%, #70bafe 100%);
    background-image: linear-gradient(to right, #70bafe 0%, #998dff 51%, #70bafe 100%);
    background-size: 200% auto;
    z-index: 2;
    width: 126px;
    padding: 0px;
    display: inline-block;
    -webkit-transition: all 400ms ease-out;
    -o-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    text-align: center;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff;
    font-size: 15px;
    font-family: "montserrat", sans-serif;
    line-height: 42px;
    border: 6px solid rgba(204, 241, 255, 0.5);
    border-radius: 33px;
}

.submit_btn:hover {
    background-position: right center;
    color: #fff;
}

.submit_btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    border: 6px solid rgba(204, 241, 255, 0.5);
}

.solid_btn {
    padding: 0px 53px;
    background: #6fbcfe;
    z-index: 2;
    display: inline-block;
    -webkit-transition: all 400ms ease-out;
    -o-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    text-align: center;
    color: #fff;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    line-height: 55px;
    border: 6px solid rgba(204, 241, 255, 0.5);
    border-radius: 33px;
}

.solid_btn:hover {
    background-position: right center;
    color: #fff;
}

/* Button Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Get In Touch Area css
============================================================================================ */
.get_in_touch_area {
    width: 100%;
    display: flex;
    justify-content: center;
}

/* End Get In Touch Area css
============================================================================================ */
/* World Map Area css
============================================================================================ */
.world_map_inner {
    text-align: center;
    position: relative;
}

.bd_callout {
    text-align: left;
    position: absolute;
    left: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 550px;
    width: 100%;
    background: #fff;
    -webkit-box-shadow: 0px 0px 46px 15px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 46px 15px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 46px 15px rgba(0, 0, 0, 0.15);
    border-left: 8px;
    border-style: solid;
    border-image: -moz-linear-gradient(top, #9b8aff 0%, #6ebcfe 100%);
    -webkit-border-image: -webkit-linear-gradient(top, #9b8aff 0%, #6ebcfe 100%);
    border-image: -webkit-linear-gradient(top, #9b8aff 0%, #6ebcfe 100%);
    -o-border-image: -o-linear-gradient(top, #9b8aff 0%, #6ebcfe 100%);
    border-image: -webkit-gradient(linear, left top, left bottom, from(#9b8aff), to(#6ebcfe));
    border-image: linear-gradient(to bottom, #9b8aff 0%, #6ebcfe 100%);
    border-image-slice: 1;
    border-top: 0px;
    border-bottom: 0px;
    border-right: 0px;
    /* padding-left: 70px; */
    padding-top: 35px;
    padding-bottom: 35px;
}

.bd_callout h3 {
    font-size: 15px;
    color: #0b1033;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    padding-bottom: 15px;
}

.bd_callout p {
    color: #7c8d93;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 28px;
    display: inline-block;
}

.bd_callout h4 {
    /* display: inline-block; */
}

.bd_callout h4 a {
    display: block;
    color: #7c8d93;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 28px;
}

/* End World Map Area css
============================================================================================ */
/* Contact Map Area css
============================================================================================ */
.contact_map_area {
    position: relative;
    overflow: hidden;
}

.contact_map_area .mapBox1 {
    min-height: 670px;
}

.contact_map_area .map_location_box {
    position: absolute;
    left: 30px;
    top: 160px;
    width: 100%;
}

.contact_map_area .map_location_box .bd_callout {
    position: relative;
    top: 0px;
    left: 0px;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.contact_map_area .map_location_box .bd_callout:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 14px 0 0;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: -14px;
    top: 0px;
}

@media (max-width: 768px) {
    .bd_callout {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}

/* End Contact Map Area css
============================================================================================ */
/* Contact Success and error Area css
============================================================================================ */
.modal-message .modal-dialog {
    position: absolute;
    top: 36%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) !important;
    -ms-transform: translateX(-50%) translateY(-50%) !important;
    transform: translateX(-50%) translateY(-50%) !important;
    margin: 0px;
    max-width: 500px;
    width: 100%;
}

.modal-message .modal-dialog .modal-content .modal-header {
    text-align: center;
    display: block;
    border-bottom: none;
    padding-top: 50px;
    padding-bottom: 50px;
}

.modal-message .modal-dialog .modal-content .modal-header .close {
    position: absolute;
    right: -15px;
    top: -15px;
    padding: 0px;
    color: #fff;
    opacity: 1;
    cursor: pointer;
}

.modal-message .modal-dialog .modal-content .modal-header h2 {
    display: block;
    text-align: center;
    color: #97ccfe;
    padding-bottom: 10px;
}

.modal-message .modal-dialog .modal-content .modal-header p {
    display: block;
}

/* End Contact Success and error Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Circle Chart Area css
============================================================================================ */
.circle_chart_area {
    padding: 85px 0px 80px 0px;
}

.circle_progress .circle_progress_inner {
    position: relative;
    max-width: 175px;
    margin: auto;
}

.circle_progress .circle_progress_inner strong {
    position: absolute;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    text-align: center;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #0b1033;
    margin-top: -13px;
}

.circle_progress .circle_progress_inner h4 {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    color: #7c8d93;
    font-weight: 500;
    position: absolute;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    margin-top: 18px;
}

.circle_progress .circle_progress_inner .percentage {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: absolute;
    left: -8px;
    top: 2px;
    background: #80cfff;
    /* Old browsers */
    background: -moz-linear-gradient(left, #80cfff 0%, #997aff 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #80cfff 0%, #997aff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#80cfff), to(#997aff));
    background: -o-linear-gradient(left, #80cfff 0%, #997aff 100%);
    background: linear-gradient(to right, #80cfff 0%, #997aff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80cfff', endColorstr='#997aff',GradientType=1 );
    /* IE6-9 */
}

.circle_progress .circle_progress_inner .percentage:after {
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    top: 5px;
    background: #fff;
}

/* End Circle Chart Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Portfolio Area css
============================================================================================ */
.portfolio_area {
    border-bottom: 2px solid #fff;
}

.portfolio_filter {
    padding: 95px 0px 130px 0px;
}

.portfolio_filter ul li {
    display: inline-block;
    margin-right: 40px;
}

.portfolio_filter ul li a {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #7e8d9c;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}

.portfolio_filter ul li:last-child {
    margin-right: 0px;
}

.portfolio_filter ul li:hover a,
.portfolio_filter ul li.active a {
    color: #0b1033;
}

.wd_25 {
    width: 25%;
}

.wd_50 {
    width: 50%;
}

.ms_portfolio_inner {
    overflow: hidden;
}

.ms_portfolio_inner .ms_p_item {
    float: left;
}

.ms_portfolio_inner .ms_p_item img {
    width: 100%;
}

/* End Portfolio Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Blog Main Area css
============================================================================================ */
.blog_img {
    position: relative;
}

.blog_img .blog_date {
    position: absolute;
    left: 0px;
    top: 40px;
    max-width: 120px;
    width: 100%;
    background: #6fbcfe;
    /* Old browsers */
    background: -moz-linear-gradient(left, #6fbcfe 0%, #9b8aff 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #6fbcfe 0%, #9b8aff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#6fbcfe), to(#9b8aff));
    background: -o-linear-gradient(left, #6fbcfe 0%, #9b8aff 100%);
    background: linear-gradient(to right, #6fbcfe 0%, #9b8aff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6fbcfe', endColorstr='#9b8aff',GradientType=1 );
    /* IE6-9 */
    text-align: center;
    color: #fff;
    padding: 18px 0px 16px 0px;
}

.blog_img .blog_date h4 {
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    padding-bottom: 5px;
}

.blog_img .blog_date h5 {
    font-size: 12px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
}

.blog_text {
    padding: 40px 30px 65px 30px;
}

.blog_text h4 {
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #0b1033;
    padding-bottom: 5px;
}

.blog_text .blog_author {
    padding-bottom: 30px;
}

.blog_text .blog_author a {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #7c8d93;
    margin-right: 20px;
    position: relative;
}

.blog_text .blog_author a:before {
    content: "|";
    position: absolute;
    right: -13px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #7c8d93;
}

.blog_text .blog_author a:last-child {
    margin-right: 0px;
}

.blog_text .blog_author a:last-child:before {
    display: none;
}

.blog_text p {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 28px;
    color: #7c8d93;
}

.blog_text p + p {
    padding-top: 30px;
}

.blog_text .more_btn {
    margin-top: 45px;
}

.blog_main_item {
    -webkit-box-shadow: 0px 14px 38px 0px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 14px 38px 0px rgba(0, 0, 0, 0.11);
    margin-bottom: 100px;
}

.blog_main_item:last-child {
    margin-bottom: 0px;
}

.pagination_area {
    padding-top: 100px;
}

.pagination_area .pagination li {
    margin-right: 10px;
}

.pagination_area .pagination li a {
    border-radius: 0px;
    border: none;
    background: transparent;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #7c8d93;
    padding: 0px;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}

.pagination_area .pagination li:last-child {
    margin-right: 0px;
}

.pagination_area .pagination li:hover a,
.pagination_area .pagination li.active a {
    color: #0b1033;
    background: transparent;
}

.r_widget {
    margin-bottom: 80px;
}

.r_widget:last-child {
    margin-bottom: 0px;
}

.r_w_title {
    padding-bottom: 25px;
}

.r_w_title h3 {
    font-size: 18px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    color: #0b1033;
}

.search_widget .input-group {
    display: block;
    position: relative;
}

.search_widget .input-group input {
    width: 100%;
    display: block;
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    border-image: -moz-linear-gradient(180deg, #9b8aff 0%, #6ebdfe 100%);
    -webkit-border-image: -webkit-linear-gradient(180deg, #9b8aff 0%, #6ebdfe 100%);
    border-image: -webkit-linear-gradient(180deg, #9b8aff 0%, #6ebdfe 100%);
    border-image: -ms-linear-gradient(180deg, #9b8aff 0%, #6ebdfe 100%);
    border-image-slice: 1;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    height: 36px;
    padding: 0px;
    line-height: 0px;
    padding-bottom: 24px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 12px;
    color: #acb6ba;
    font-family: "Roboto", sans-serif;
    font-style: italic;
}

.search_widget .input-group input.placeholder {
    font-size: 12px;
    color: #acb6ba;
    font-family: "Roboto", sans-serif;
    font-style: italic;
}

.search_widget .input-group input:-moz-placeholder {
    font-size: 12px;
    color: #acb6ba;
    font-family: "Roboto", sans-serif;
    font-style: italic;
}

.search_widget .input-group input::-moz-placeholder {
    font-size: 12px;
    color: #acb6ba;
    font-family: "Roboto", sans-serif;
    font-style: italic;
}

.search_widget .input-group input::-webkit-input-placeholder {
    font-size: 12px;
    color: #acb6ba;
    font-family: "Roboto", sans-serif;
    font-style: italic;
}

.search_widget .input-group .input-group-btn {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 3;
}

.search_widget .input-group .input-group-btn .btn-secondary {
    background: transparent;
    border-radius: 0px;
    padding: 0px;
    border: none;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
    margin: 0px;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #acb6ba;
    cursor: pointer;
}

.search_widget .input-group .input-group-btn .btn-secondary:hover {
    color: #0b1033;
}

.categories_widget ul li {
    margin-bottom: 10px;
}

.categories_widget ul li a {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #7c8d93;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}

.categories_widget ul li:last-child {
    margin-bottom: 0px;
}

.categories_widget ul li:hover a {
    color: #70a7e4;
}

.insta_widget ul {
    overflow: hidden;
    margin: -3px;
}

.insta_widget ul li {
    width: calc(100% / 3);
    float: left;
    overflow: hidden;
}

.insta_widget ul li a {
    display: block;
    padding: 3px;
}

.tag_widget ul {
    margin-right: -8px;
    margin-bottom: -12px;
}

.tag_widget ul li {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 12px;
}

.tag_widget ul li a {
    display: inline-block;
    background: #f4f8fc;
    padding: 0px 25px;
    line-height: 40px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #7c8d93;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}

.tag_widget ul li:hover a {
    border-radius: 20px;
    background-image: -moz-linear-gradient(10deg, #6ebdfe 0%, #9b8aff 100%);
    background-image: -webkit-linear-gradient(10deg, #6ebdfe 0%, #9b8aff 100%);
    background-image: -ms-linear-gradient(10deg, #6ebdfe 0%, #9b8aff 100%);
    color: #fff;
}

.quote_widget p {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
    color: #7c8d93;
    line-height: 30px;
    font-size: 15px;
    padding-top: 25px;
}

.s_blog_social {
    padding-bottom: 80px;
    padding-left: 30px;
}

.s_blog_social h3 {
    font-size: 18px;
    color: #0b1033;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    display: inline-block;
}

.s_blog_social ul {
    display: inline-block;
}

.s_blog_social ul li {
    display: inline-block;
    margin-left: 30px;
}

.s_blog_social ul li a {
    font-size: 12px;
    color: #0b1033;
}

.s_blog_social ul li:first-child {
    margin-left: 40px;
}

.blog_author .media {
    vertical-align: middle;
}

.blog_author .media .d-flex {
    padding-right: 20px;
    -ms-flex-item-align: center;
    align-self: center;
}

.blog_author .media .media-body h4 {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    padding-bottom: 25px;
}

.blog_author .media .media-body h4 span {
    color: #7c8d93;
}

.blog_author .media .media-body p {
    font-size: 15px;
    line-height: 28px;
    color: #7c8d93;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.blog_comment h3 {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #0b1033;
    padding-bottom: 80px;
    padding-top: 80px;
}

.blog_comment .media {
    margin-bottom: 35px;
}

.blog_comment .media .d-flex {
    display: block !important;
    padding: 0px 0px 0px 25px;
    max-width: 144px;
    width: 100%;
}

.blog_comment .media .d-flex h4 {
    color: #0b1033;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    display: block;
    padding-bottom: 6px;
}

.blog_comment .media .d-flex h5 {
    color: #7c8d93;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 12px;
    display: block;
}

.blog_comment .media .media-body {
    padding-left: 20px;
}

.blog_comment .media .media-body p {
    color: #7c8d93;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    padding-bottom: 15px;
}

.blog_comment .media .media-body a {
    color: #0b1033;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 12px;
}

.blog_comment .media:last-child {
    margin-bottom: 0px;
}

.blog_comment_form {
    max-width: 560px;
}

.blog_comment_form h3 {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #0b1033;
    padding: 75px 0px 55px 0px;
}

.blog_comment_form .contact_us_form {
    margin-top: 0px;
}

.blog_comment_form .contact_us_form .form_group input {
    height: 55px;
}

.blog_comment_form .contact_us_form .form_group:last-child {
    margin-bottom: 0px;
}

/* End Blog Main Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Footer Area css
============================================================================================ */
.footer_widget_area {
    background: url(../../../Assets/Images/footer-bg.jpg) no-repeat scroll center center;
    background-size: cover;
    padding: 30px 130px 30px 130px;
    display: flex;
    gap: 10rem;
    width: 100%;
    height: 100%;
}
.footer_widget_area .container1 {
    width: 33.33%;
    text-align: center;
    /* display: flex; */
}
.projectList {
    text-align: left;
    display: flex;
    gap: 2rem;
}
.listType {
    list-style: none;
}
.footer_widget_area .container2 {
    width: 33.33%;
    color: rgba(0, 0, 0, 0.8) !important;
}
.footer_widget_area .container3 {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}
.footer_widget_area .container1 span {
    display: block;
    font-size: 1.5rem;
    padding: 0 5rem;
}
.footer_widget_area .container1 p {
    font-size: 1rem;
    margin: 0.5rem 0;
    /* padding: 5rem; */
}
.footer_widget_area .container1 ul {
    font-size: 1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
}
.footer_widget_area .container3 span {
    font-size: 1.5rem;
    /* padding: 0 5rem; */
    text-align: left;
}
.CTABtn {
    display: flex;
    flex-direction: column;
    width: fit-content;
}
.CTABtn button {
    padding: 1rem 2rem;
    background-color: white;
    color: black;
    margin-top: 2rem;
    border-radius: 5rem;
}
img.footerLogo {
    width: 40%;
    margin-bottom: 2rem;
}
.f_title {
    padding-bottom: 45px;
}

.f_title h3 {
    font-size: 16px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.f_about_widget {
    padding-right: 35px;
}

.f_about_widget img {
    padding-bottom: 55px;
}

.f_about_widget p {
    font-size: 15px;
    line-height: 28px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.f_insta_widget ul {
    overflow: hidden;
    margin: -1px;
}

.f_insta_widget ul li {
    width: calc(100% / 4);
    float: left;
}

.f_insta_widget ul li a {
    display: inline-block;
    padding: 1px;
}

.f_insta_widget ul li a img {
    width: 100%;
}

.f_subs_widget .input-group {
    display: block;
}

.f_subs_widget .input-group input {
    display: block;
    width: 100%;
    border-radius: 0px;
    border: none;
    height: 52px;
    padding: 0px 30px;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-style: italic;
    color: #9b9b9b;
}

.f_subs_widget .input-group input.placeholder {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-style: italic;
    color: #9b9b9b;
}

.f_subs_widget .input-group input:-moz-placeholder {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-style: italic;
    color: #9b9b9b;
}

.f_subs_widget .input-group input::-moz-placeholder {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-style: italic;
    color: #9b9b9b;
}

.f_subs_widget .input-group input::-webkit-input-placeholder {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-style: italic;
    color: #9b9b9b;
}

.f_subs_widget .input-group .input-group-btn {
    margin-left: 0px;
    display: block;
    margin-top: 25px;
}

.f_subs_widget .input-group .input-group-btn .submit_btn {
    margin-left: 0px;
    border-radius: 35px !important;
}

.f_subs_widget .input-group .input-group-btn .submit_btn:hover {
    border-color: #8cc4fb;
}

.footer_copyright {
    overflow: hidden;
    background: #11173b;
    padding: 10px 0px;
}

.footer_copyright .float_sm_left span {
    font-size: 14px;
    color: #551a8b;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 29px;
}

.footer_copyright .float_sm_right .social_icon {
    display: inline-block;
    margin-right: 32px;
    font-size: 14px;
    color: #2a2f56;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}

/* .footer_copyright .float_sm_right ul li a {
    font-size: 14px;
    color: #2a2f56;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
} */

.footer_copyright .float_sm_right ul li:last-child {
    margin-right: 0px;
}

.footer_copyright .float_sm_right .social_icon:hover a {
    cursor: pointer;
    color: #fff;
}

/* End Footer Area css
============================================================================================ */
/*---------------------------------------------------- */

/* Service css
============================================================================================ */
.containerOrbiz {
    max-width: 1400px;
    /* margin-left: 100px; */
    padding: 2rem;
}

.serviceArea {
    padding: 164px 0px 100px 0;
    width: 100%;
}

.serviceItem {
    border: 1px solid #b10400;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding-bottom: 30px;
    height: 110%;
    /* justify-content: space-between; */
}
.serviceItem:hover {
    -webkit-box-shadow: 0px 14px 38px 0px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 14px 38px 0px rgba(0, 0, 0, 0.11);
}
.serviceItem .moreBtn {
    opacity: 0;
}

.serviceItem:hover .moreBtn {
    opacity: 1;
}

.serviceItem img {
    max-width: 100%;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
    top: -64px;
}

.serviceItem h3 {
    font-size: 20px;
    font-family: "Cantata One", serif;
    color: #b10400;
    text-transform: uppercase;
    padding: 90px 0px 10px 0;
}

.serviceItem p {
    font-size: 14px;
    line-height: 26px;
    font-family: "Open Sans", sans-serif;
    color: #666666;
    padding: 15px 5px 5px 5px;
}

/* End Service css
============================================================================================ */
/*---------------------------------------------------- */
section.get_in_touch_area {
    /* padding: 0 5rem; */
    display: flex;
}
section.container {
    width: 50%;
}
.world_map_area {
    width: 50%;
    padding-bottom: 4rem;
}

.col_md_2 {
    width: 16.66%;
}

.col_lg_6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col_lg_12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.img_fluid {
    max-width: 100%;
    height: auto;
}

.form_control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.rounded_circle {
    border-radius: 50% !important;
}

.feature_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    justify-items: center;
    align-items: center;
}
.feature_content .item {
    /* background: #eee;  */
    text-align: center;
    padding: 1.5rem 0;
    border-radius: 1.5rem;
    position: relative;
    min-height: 280px;
    padding: 20px 12px;
    display: flex;
}
.feature_content > :nth-child(even) {
    background: url("../../../Assets/Images/3d-shap.png") no-repeat center center;
    background-size: 100% 100%;

    display: flex;
}
.feature_content .item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    /* background: #eee; */
    opacity: 0.2;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
}

@media (max-width: 768px) {
    .cTitle h2 {
        font-size: 43px;
    }
    .feature_content {
        grid-template-columns: repeat(1, 1fr);
    }
    .world_map_area {
        width: 100%;
    }
    section.get_in_touch_area {
        /* padding: 0 5rem; */
        display: flex;
        padding: 218px 2rem 2rem 2rem;
    }
    .footer_widget_area {
        flex-direction: column;
        padding: 30px;
        gap: 4rem;
    }
    .footer_widget_area .container1 {
        width: 100%;
        /* text-align: center; */
        /* display: flex; */
    }
    .footer_widget_area .container2 {
        width: 100%;
        /* text-align: center; */
        /* display: flex; */
    }
    .footer_widget_area .container3 {
        width: 100%;
        /* display: flex; */
        align-items: center;
    }
    .footer_widget_area .container3 span {
        text-align: center;
    }
}

@media (min-width: 769px) and (max-width: 991px) {
    .feature_content {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 992px) {
    .navbar_expand_lg {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}
